import * as bootstrap from 'bootstrap';
import naja from 'naja';
import { isMobile } from 'mobile-device-detect';

export class MiniCart {

	initialize(naja) {
		naja.snippetHandler.addEventListener('afterUpdate', this.afterUpdate.bind(this));
		this.initDropdown();

		document.addEventListener('click', (e) => {
			if (!document.querySelector('.js-header-cart')?.contains(e.target) && !e.target.classList.contains('js-header-cart')) {
				if (typeof this.dropdown !== 'undefined') {
					this.dropdown.hide();
				}
			}
		});
	}

	afterUpdate({detail}) {
		if (detail.snippet.classList.contains('js-header-cart')) {
			this.initDropdown();
		}
	}

	initDropdown() {
		const el = document.querySelector('.js-header-cart');
		if (el !== null && el.querySelector('.dropdown-menu') !== null) {
			const dropdownToggleEl = el.querySelector('.dropdown-toggle');
			const dropdownEl = dropdownToggleEl.parentElement;
			const dropdown= this.dropdown = new bootstrap.Dropdown(dropdownEl);

			dropdownToggleEl.addEventListener('click', (e) => {
				if (e.pointerType !== 'mouse' || (e.pointerType === 'mouse' && window.outerWidth < 576)) {
					e.preventDefault();
					dropdown.toggle();
				}
			});
			el.addEventListener('mouseenter', (e) => {
				if (!isMobile && window.outerWidth >= 576) {
					dropdown.show();
				}
			});
			el.addEventListener('mouseleave', (e) => {
				if (!isMobile && window.outerWidth >= 576) {
					if (e.toElement?.tagName !== 'BODY') {
						dropdown.hide();
					}
				}
			})
		}
	}

}

naja.registerExtension(new MiniCart());