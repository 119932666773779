// UI for front
import naja from "naja";

// Required dependencies
import 'jquery';
global.$ = global.jQuery = jQuery;

// Assets
import '../../../theme/front/style.scss';

import '../../components/naja';
import '../../components/netteForms';
import onDomReady from '../../utils/onDomReady';
import '../../components/naja-errorHandler';
import '../../components/fid-remover';
import {SpinnerExtension} from '@/js/components/naja-spinner';
import {ModalExtension} from '@/js/components/naja-modal';
import MainMenu from "@/js/components/MainMenu";
import '../../components/bootstrap';
import '../../components/SearchBox';
import blueimp from 'blueimp-gallery';
import 'blueimp-gallery/js/blueimp-gallery-indicator';
import '../../utils/utils';

import '../../components/Spinbox';

import './cart';
import './order';
import './product';

import Register from '../../utils/Register';
import AgeVerificationModal from "@/js/components/AgeVerificationModal";
import {cs, en, sk} from "intl-tel-input/i18n";
import intlTelInput from "intl-tel-input";
// import IMask from "imask";

naja.registerExtension(new SpinnerExtension('body'));
naja.registerExtension(new ModalExtension('front'));
naja.formsHandler.netteForms = Nette;

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    (new Register()).registerNewContent($(event.detail.snippet));
});

naja.historyHandler.addEventListener('buildState', (event) => {
	if (event.detail.options.reloadOnRestore) {
		event.detail.state.reloadOnRestore = true;
	}
});

naja.historyHandler.addEventListener('restoreState', (event) => {
	if (event.detail.state.reloadOnRestore) {
		event.stopImmediatePropagation();
		naja.makeRequest('GET', event.detail.state.href, null, { history: false });
	}
});

naja.addEventListener('complete', (event) => {
	if (event.detail.options.scrollTo) {
		event.detail.options.scrollTo.scrollIntoView();
	}
});

onDomReady(() => {	
	
	/*
	NAJA
	history: false,
    forceRedirect: true,
	*/
	
	naja.initialize();

	document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
	
    $('body').on('click', '.bi-gallery a:hrefImage', function(event) {	
        event = event || window.event;
        var target = event.target || event.srcElement, link = this, options = {index: link, event: event};
        var links = $(this).closest('.bi-gallery').find('a:hrefImage');
        blueimp(links, options);
    });
    
	$('body').on('click', '.bi-p-gallery a[data-bi]:hrefImage', function(event) {
        event = event || window.event;
        var target = event.target || event.srcElement, link = this, options = {index: link, event: event};
        var links = $('.bi-p-gallery a[data-bi]');
        blueimp(links, options);
    });

	new AgeVerificationModal();
	MainMenu.init($('body'));

	document.querySelectorAll('.js-iti').forEach(function(el) {
		let locale = en;
		if (el.dataset.itiLocale === 'cs') {
			locale = cs;
		} else if (el.dataset.itiLocale === 'sk') {
			locale = sk;
		}

		const name = el.getAttribute('name');
		el.setAttribute('name', '_' + name);

		intlTelInput(el, {
			i18n: locale,
			initialCountry: el.dataset.itiCountry,
			strictMode: true,
			separateDialCode: true,
			onlyCountries: ['cz', 'sk'],
			//countryOrder: ['cz', 'sk'],
			//customPlaceholder: () => '',
			autoPlaceholder: false,
			useFullscreenPopup: false,
			hiddenInput: () => ({ phone: name}),
			loadUtilsOnInit: () => import("intl-tel-input/utils")
		});
	});

	/*
	document.querySelectorAll('.js-imask').forEach(function(el) {
		if (el.dataset.hasOwnProperty('imaskMask')) {
			IMask(el, {
				mask: value => (new RegExp(`${el.dataset.imaskMask}`)).test(value)
			});
		}
	});
	 */

    (new Register()).registerNewContent($('body'));
});

// Webpack HMR
if (module.hot) {
	module.hot.accept();
}