import Register from '../../../utils/Register';
import netteForms from 'nette-forms';

export default class DeliveryData {

    constructor ($container) {
        const _this = this;

		$('#frm-orderForm-billingAddress-companyCustomer, #frm-orderForm-deliveryToAnotherAddress, #frm-orderForm-signUp', $container)
        	.on('change', this.updateRequires);
				
		$('#frm-orderForm-customerBillingAddressId', $container)
			.on('change', () => this.fillBillingAddress());
			
		$('#frm-orderForm-customerDeliveryAddressId', $container)
			.on('change', () => this.fillDeliveryAddress());
    }
    
    updateRequires() {
		if ($('#frm-orderForm-signUp').is(':checked')) {
			$('label[for="frm-orderForm-customerPassword"]').addClass('required');
		} else {
			$('label[for="frm-orderForm-customerPassword"]').removeClass('required');
		}

		if ($('#frm-orderForm-billingAddress-companyCustomer').is(':checked')) {
			$('label[for="frm-orderForm-billingAddress-companyName"], label[for="frm-orderForm-billingAddress-companyNumber"]').addClass('required');
			$('label[for="frm-orderForm-billingAddress-firstName"], label[for="frm-orderForm-billingAddress-lastName"]').removeClass('required');
		} else {
			$('label[for="frm-orderForm-billingAddress-companyName"], label[for="frm-orderForm-billingAddress-companyNumber"]').removeClass('required');
			$('label[for="frm-orderForm-billingAddress-firstName"], label[for="frm-orderForm-billingAddress-lastName"]').addClass('required');			
		}
	
		if ($('#frm-orderForm-deliveryToAnotherAddress').is(':checked')) {
			$('#deliveryAddress').find('label').not('[for="frm-orderForm-deliveryAddress-companyName"]').not('[for="frm-orderForm-deliveryAddress-_deliveryCountry"]').not('[for="frm-orderForm-customerDeliveryAddressId"]').addClass('required');
		} else {
			$('#deliveryAddress label').removeClass('required');
		}
	}   
	
	fillBillingAddress() {
		const billingAddressFields = ['companyCustomer', 'companyName', 'companyNumber', 'companyTaxNumber', 'firstName', 'lastName', 'email', 'telephone', 'street', 'houseNumber', 'city', 'postcode'];
		const $selectedCustomerBillingAddress = $('#frm-orderForm-customerBillingAddressId :selected');
		const customerBillingAddressData = $selectedCustomerBillingAddress.data('billing-address');

		billingAddressFields.forEach(function(value) {
			const $field = $('#billingAddress [name*="' + value + '"]');
			if ($field.is(':checkbox')) {
				$field.prop('checked', false);
			} else {
				$field.val('');
			}
		});

		if (typeof customerBillingAddressData !== "undefined") {
			for (const field in customerBillingAddressData) {
			    if (customerBillingAddressData.hasOwnProperty(field)) {
					const value = customerBillingAddressData[field];
					if (billingAddressFields.includes(field)) {
						const $field = $('#billingAddress [name*="' + field + '"]');
						if ($field.is(':checkbox')) {
							$field.prop('checked', value);
						} else {
							$field.val(value);
						}
					}
			    }
			}
		}
		
		netteForms.toggleForm(document.querySelector('#' + $selectedCustomerBillingAddress.closest('form').attr('id')));
		this.updateRequires();
	}
	
	fillDeliveryAddress() {
		const deliveryAddressFields = ['firstName', 'lastName', 'companyName', 'telephone', 'street', 'houseNumber', 'city', 'postcode'];
		const $selectedCustomerDeliveryAddress = $('#frm-orderForm-customerDeliveryAddressId :selected');
		const customerDeliveryAddressData = $selectedCustomerDeliveryAddress.data('delivery-address');

		deliveryAddressFields.forEach(function(value) {
			const $field = $('#deliveryAddress [name*="' + value + '"]');
			if ($field.is(':checkbox')) {
				$field.prop('checked', false);
			} else {
				$field.val('');
			}
		});
	
		if (typeof customerDeliveryAddressData !== "undefined") {
			for (const field in customerDeliveryAddressData) {
			    if (customerDeliveryAddressData.hasOwnProperty(field)) {
					const value = customerDeliveryAddressData[field];
					if (deliveryAddressFields.includes(field)) {
						const $field = $('#deliveryAddress [name*="' + field + '"]');
						if ($field.is(':checkbox')) {
							$field.prop('checked', value);
						} else {
							$field.val(value);
						}
					}
			    }
			}	
		}
		
		netteForms.toggleForm(document.querySelector('#' + $selectedCustomerDeliveryAddress.closest('form').attr('id')));
		this.updateRequires();
	}

    static init ($container) {
		if ($container.find('.js-order-delivery-data').length > 0) {
	        const deliveryData = new DeliveryData($container);
	        deliveryData.updateRequires();
        }
        
    }
}

(new Register()).registerCallback(DeliveryData.init, 'DeliveryData.init');