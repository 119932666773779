import Register from '../../../utils/Register';
import naja from 'naja';

export default class ProductPagination {

	static init ($container) {
		naja.uiHandler.addEventListener('interaction', (event) => {
			if (event.detail.element.parentElement.classList.contains('pagination')) {
				event.detail.options.scrollTo = document.querySelector('.product-list');
			}
		});
	}

}

(new Register()).registerCallback(ProductPagination.init, 'ProductPagination.init');